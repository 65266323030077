<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 我的钱包
 * @Date: 2020-12-23 22:19:40
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-25 11:33:35
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/mywallet.vue
-->
<template>
  <div class="user_content">
    <div class="waller_total">
      <span>¥</span><span>{{ historyBalance }}</span>
      <div class="record" @click="settlement">结算记录</div>
    </div>
    <van-cell-group class="waller_list">
      <van-cell title="佣金余额" is-link :value="'¥ ' + balance" to="/Withdrawal" />
      <van-cell title="消费余额" is-link :value="'¥ ' + historyBalance" to="/consumption" />
      <van-cell title="采购结算" is-link :value="'¥ ' + gPackage" to="/Purchase" />
      <van-cell title="销售结算" is-link :value="'¥ ' + sale" to="/sale" />
      <van-cell title="酒店立减" :value="unUse + '次'" />
    </van-cell-group>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup, Toast } from 'vant'

Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Toast)
import {
  newGetSales
} from '@/services/userApi'

export default {
  filters: {},
  data() {
    return {
      balance: '',
      historyBalance: '',
      sale: '',
      unUse: '',
      gPackage: ''
    }
  },
  mounted() {
    this.GetSales()
  },
  methods: {
    GetSales() {
      newGetSales().then(res => {
        if (Number(res.code) === 200) {
          this.balance = res.data.balance
          this.historyBalance = res.data.historyBalance
          this.sale = res.data.sale
          this.unUse = res.data.unUse
          this.gPackage = res.data.gPackage
        } else {
          Toast(res.msg)
        }
      })
    },
    // 进结算记录
    settlement() {
      this.$router.push({ path: '/Settlement' })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;
}

.waller_total {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7263C;
  height: 150px;
  text-align: center;

  & > span {
    color: #FFFFFF;
    font-size: 15px;
  }

  & > span:nth-child(2) {
    font-size: 39px;
    font-weight: 600;
  }

  .record {
    position: absolute;
    top: 60px;
    right: 0;
    color: #F7263C;
    font-size: 14px;
    background: #FFFFFF;
    padding: 4px 5px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
}

.waller_list {
  width: 93%;
  position: absolute;
  top: 120px;
  background: #FFFFFF;
  border-radius: 7px;
  margin: 13px;
  padding: 4px;
}

.van-cell__title {
  text-align: left;
}

.van-cell__value {
  color: #E33F44;
  font-size: 14px;
}
</style>
